import { TASK_MANAGEMENT_ACCESS } from "@/helpers/permissionsConstant";
import { TASK_MANAGEMENT_BOARDS_ACCESS } from "@/helpers/permissionsConstant";
import { MILESTONE_BOARD_ACCESS } from "@/helpers/permissionsConstant";
import { TASK_MANAGEMENT_GLOBAL_TRACKER_ACCESS } from "@/helpers/permissionsConstant";

export default [
  {
    title: "Task ",
    icon: "TrelloIcon",
    gate: TASK_MANAGEMENT_ACCESS,
    children: [
      {
        title: "Projects",
        gate: TASK_MANAGEMENT_BOARDS_ACCESS,
        children: [
          {
            title: "Project",
            route: "admin-task-boards",
            gate: MILESTONE_BOARD_ACCESS,
          },
          {
            title: "All Task",
            route: "employee-global-task-tracker",
            gate: MILESTONE_BOARD_ACCESS,
          },
        ],
      },
      {
        title: "Global Tracker",
        route: "admin-global-task-activity-tracker",
        gate: TASK_MANAGEMENT_GLOBAL_TRACKER_ACCESS,
      },
    ],
  },
];
