import {
  LEAD_MANAGEMENT_ACCESS,
  LEAD_CATEGORY_ACCESS,
  LEAD_SOURCE_ACCESS,
  LEAD_STATUS_ACCESS,
  LEAD_DETAILS_ACCESS,
  MY_LEAD_ACCESS,
  LEAD_ACCESS
} from "@/helpers/permissionsConstant";
export default [
  {
    title: "Lead",
    icon: "PhoneCallIcon",
    gate: LEAD_MANAGEMENT_ACCESS,
    children: [
      {
        title: "Categories",
        route: "admin-lead-categories",
        gate: LEAD_CATEGORY_ACCESS,
      },
      {
        title: "Sources",
        route: "admin-lead-sources",
        gate: LEAD_SOURCE_ACCESS,
      },
      {
        title: "Status",
        route: "admin-lead-status",
        gate: LEAD_STATUS_ACCESS,
      },
      {
        title: "All Leads",
        route: "admin-lead-management",
        gate: LEAD_ACCESS,
      },
      {
        title: "My Leads",
        route: "my-lead-management",
        gate: MY_LEAD_ACCESS,
      },
    ],
  },
];
